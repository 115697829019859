import {
	convertToHalfWidth,
	checkFullWidthContent,
} from '@/util/formatNumber';

export default {
	data() {
		return {
			errorNoteMsg: '',
		};
	},
	watch: {
    async 'model.productionCosts'(newProductionCosts, oldProductionCosts) {
			newProductionCosts = convertToHalfWidth(newProductionCosts);
			oldProductionCosts = convertToHalfWidth(oldProductionCosts);

			if (newProductionCosts == null) {
				return;
			}

			// Check if input contains non-numeric characters
			if (/[^0-9]/.test(newProductionCosts)) {
				// Replace all non-numeric characters to empty
				newProductionCosts = newProductionCosts.replace(/[^0-9]/g, '');
				oldProductionCosts = oldProductionCosts.replace(/[^0-9]/g, '');
				this.model.productionCosts = newProductionCosts;
			}
		},
	},
	methods: {
		convertToHalfWidth,

    onBlurHourlyRate(event) {
      if (checkFullWidthContent(event.target.value)) {
        this.model.productionCosts = convertToHalfWidth(event.target.value);
			} else {
				this.errorNoteMsg = '';
			}
		},
	},
	async mounted() {
	},
};
