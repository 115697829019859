<template>
	<div id="register-project">
		<div class="screen-title">案件登録</div>
		<b-container fluid>
			<validation-observer class="w-100" v-slot="{ handleSubmit }" ref="formValidator">
				<b-form
					class="w-100"
					role="form"
					@submit.prevent="handleSubmit(onProjectRegister)"
				>
					<b-row class="mx-0">
						<b-col class="mx-0 px-0">
							<b-row class="mx-0 d-flex row-gap-20">
								<card class="my-0 w-100">
									<div class="card-title">基本情報</div>
									<b-row class="custom__mx-3 pb-27 col-gap-30">
										<div class="col-md col-xs-12 px-0 res_margin_bottom">
											<base-input
												clearable
												alternative
												placeholder="案件名"
												name="案件名"
												label="案件名"
												:rules="{ required: true }"
												v-model="model.projectName"
												:inputRequired="true"
												:labelClasses="'custom-form-input-label'"
												:inputClasses="'custom-input-class'"
											>
											</base-input>
										</div>
										<div class="col px-0">
											<base-input
												label="クライアント"
												name="クライアント"
												note="※ 新規のクライアントは、左メニュー「クライアント登録」を先に行ってください。"
												:rules="{
													required: true,
													validate: [
														[model.clientInfo],
														'クライアントは必須です',
														validateClientInfo,
													],
												}"
												:inputRequired="true"
												:inputClasses="'custom-input-class'"
												:labelClasses="'custom-form-input-label'"
											>
												<el-select
													placeholder="選択なし"
													v-model="model.clientInfo"
													clearable
													as="select"
												>
													<el-option
														:key="register_option.value"
														:label="register_option.label"
														:value="register_option"
														class="register_option"
													>
													</el-option>
													<el-option
														v-for="client in clientOptions"
														:key="client.value"
														:label="client.label"
														:value="client"
													>
													</el-option>
												</el-select>
											</base-input>
										</div>
									</b-row>
									<b-row class="custom__mx-3 pb-27 col-gap-30">
										<b-col class="px-0">
											<b-row class="mx-0 d-flex flex-column">
												<b-row class="mx-0 mt-2 col-gap-30">
													<div class="col-md col-xs-12 px-0 pb-27 res_margin_bottom">
														<b-row class="mx-0">
															<div class="elm-title">通知ツール</div>
														</b-row>
														<b-row class="mx-0 mt-2 col-gap-30">
															<base-radio
																:name="0"
																:radioName="'メール'"
																v-model="model.notifyMethod"
															></base-radio>
															<!-- <base-radio
																:name="1"
																:radioName="'LINE'"
																v-model="model.notifyMethod"
															></base-radio>
															<base-radio
																:name="2"
																:radioName="'両方'"
																v-model="model.notifyMethod"
															></base-radio> -->
															<base-radio
																:name="3"
																:radioName="'通知なし'"
																v-model="model.notifyMethod"
															></base-radio>
														</b-row>
														<b-row class="note mx-0 mt-2"
															>クライアントが、CoMoDeからのお知らせを受け取る方法を選べます。</b-row
														>
													</div>
													<!-- <div class="col-md col-xs-12 px-0 res_margin_bottom">
														<b-row class="mx-0">
															<div class="elm-title">源泉徴収税</div>
														</b-row>
														<b-form-checkbox-group
															id="checkbox-group-1"
															v-model="withHoldingTaxSelect"
															:options="withHoldingTaxOption"
															name="flavour-1"
															class="mt-2"
														></b-form-checkbox-group>
													</div> -->
												</b-row>
											</b-row>
										</b-col>
									</b-row>
									<b-row class="custom__mx-3 pb-27 col-gap-30">
										<b-col class="px-0">
											<b-row class="mx-0 d-flex flex-column">
												<b-row class="mx-0 mt-2 col-gap-30">
													<div
														class="col-md col-xs-12 px-0 pb-27 d-flex align-items-center col-gap-10 res_margin_bottom"
													>
														<base-input
															clearable
															alternative
															placeholder="時間単価"
															name="時間単価"
															label="時間単価"
															:rules="{ required: true }"
															v-model="model.productionCosts"
															:inputRequired="true"
															:labelClasses="'custom-form-input-label'"
															:inputClasses="'custom-input-class'"
															:note="'見積書作成時に、時間単価× 稼働時間で自動計算されます。'"
															:unit="'円'"
															:noteError="errorNoteMsg"
															@keydown="onInputKeyDown($event)"
															@blur="onBlurHourlyRate"
														>
														</base-input>
													</div>
												</b-row>
											</b-row>
										</b-col>
										<b-col class="custom__showHide"></b-col>
									</b-row>
								</card>
								<card class="my-0 w-100">
									<div class="card-title">備考</div>
									<textarea
										class="form-control"
										type="text"
										v-model="model.projectRemark"
										style="resize: none"
										rows="5"
									></textarea>
								</card>
							</b-row>
						</b-col>
					</b-row>
					<b-row class="mx-0 px-0 my-4">
						<b-col class="w-100 px-0 d-flex justify-content-center">
							<button native-type="submit" class="customButton customButton__submit">
								登録
							</button>
						</b-col>
					</b-row>
				</b-form>
			</validation-observer>
		</b-container>
		<RegisterModal
			:registerModalId="projectRegisterModalId"
			:url="lineVerifyUrl"
			@on-close="onCloseModal"
		/>
		<NotiModal
			:notificationModalId="notiModalId"
			:notiContent="notiContent"
			@on-click="onAccept"
		/>
	</div>
</template>
<script>
import ProjectService from '@/services/project.service';
import UserService from '@/services/user.service';
import ManagementService from '@/services/managementmaster.service';
import ClientService from '@/services/client.service';
import Mail from '@/services/mail.service';

import { mapState } from 'vuex';
import clientPaginationMixin from '@/mixin/clientPaginationMixin';
import hourlyRateMixin from '@/mixin/hourlyRateMixin';

import { onInputNumberOnly } from '@/util/inputNumber';

import { Select, Option, DatePicker } from 'element-ui';
import NotiModal from '@/components/Modal/NotificationModal/NotificationModal.vue';
import RegisterModal from '@/components/Modal/RegisterModal/RegisterModal.vue';

export default {
	name: 'RegisterProject',
	mixins: [clientPaginationMixin, hourlyRateMixin],
	components: {
		[DatePicker.name]: DatePicker,
		[Select.name]: Select,
		[Option.name]: Option,
		RegisterModal,
		NotiModal,
	},

	computed: {
		// Get projectId, userId from store
		...mapState(['userInfo']),
	},
	data() {
		return {
			notiModalId: 'project-register-noti',
			notiContent: '',
			model: {
				projectName: '',
				clientInfo: '',
				projectStartDate: '',
				productionCosts: undefined,
				projectRemark: undefined,
				notifyMethod: 0,
			},

			register_option: {
				label: 'クライアント登録',
				value: 'register_client',
			},

			withHoldingTaxOption: [
				{
					text: '見積書・請求書に、源泉徴収税を記載する',
					value: 1,
				},
			],

			withHoldingTaxSelect: [],

			clientOptions: [],
			loginUserId: '',
			alertMessage: '登録が完了しました',
			showAlert: false,
			successType: 'info',
			errorType: 'danger',
			alertType: 'info',
			searchProjectLink: 'searchproject',
			clientId: '',
			lineVerifyUrl: '',
			processClientUrl: '',
			processPreviewUrl: '',
			projectRegisterModalId: 'register-project',
		};
	},
	methods: {
		validateClientInfo(clientInfo) {
			if (clientInfo.value == this.register_option.value) {
				if (this.userInfo.authority === 1) {
					this.$router.replace({ name: 'CreatorCreateClient' });
				} else if (this.userInfo.authority === 2) {
					this.$router.replace({ name: 'AdminCreateClient' });
				}
				return;
			}
			if (clientInfo.value === '') return false;
			return true;
		},
		async onProjectRegister() {
			this.notiContent = '登録しますか？';
			this.$bvModal.show(`${this.notiModalId}`);
		},

		onAccept() {
			this.registerProject();
		},
		onCloseModal() {
			this.$router.push({ path: 'searchproject' });
		},
		onAlertVisibleChange(data) {
			this.showAlert = data;
			this.$router.push('searchproject');
		},

		onInputKeyDown(event) {
			onInputNumberOnly(event);
		},

		async registerProject() {
			let newProjectInfo = {
				client_id: this.model.clientInfo.value,
				comment: this.model.projectRemark,
				cost: this.model.productionCosts,
				delivery_cost: this.model.deliveryDuration,
				update_user: this.loginUserId,
				notify_method: this.model.notifyMethod,
				responsible_user: this.loginUserId,
				project_name: this.model.projectName,
				with_holding_tax: this.withHoldingTaxSelect[0]
					? this.withHoldingTaxSelect[0]
					: 0,
			};
			let [response, error] = await ProjectService.create_new_project(
				this.deleteNullFields(newProjectInfo)
			);
			this.processClientUrl = response.process_client_url;
			this.processPreviewUrl = response.process_preview_client_url;
			let [clients, clientError] = await await ClientService.getAllClient();
			let client = clients.find((client) => client.id == this.model.clientInfo.value);
			await this.createClientLineVerifyUrl(client);
			this._sendMail(client);
			if (response) {
				this.$bvModal.show(`${this.projectRegisterModalId}`);
			} else {
				this.showAlert = true;
				this.alertMessage =
					'エラーが発生したため、案件が登録できない。もう一度お願いします。';
				console.log(error.message);
				this.alertType = this.errorType;
			}
		},

		deleteNullFields(obj) {
			for (let key in obj) {
				if (obj[key] == undefined || obj[key] == '' || obj[key] == null) {
					delete obj[key];
				}
			}
			return obj;
		},

		async createLineVerifyUrl() {
			if (this.userInfo['line_verify_code']) {
				let [response, error] = await UserService.getLineVerifyUrl(this.userInfo['id']);
				if (response) {
					return response.line_verify_url;
				}
			} else {
				let [response, error] = await UserService.createLineVerifyUrl(
					this.userInfo['id']
				);
				if (response) {
					return response.line_verify_url;
				}
			}
		},
		async createClientLineVerifyUrl(client) {
			if (client['line_verify_code']) {
				let [response, error] = await ClientService.getLineVerifyUrl(client['id']);
				if (response) {
					this.lineVerifyUrl = response.line_verify_url;
				}
			} else {
				let [response, error] = await ClientService.createLineVerifyUrl(client['id']);
				if (response) {
					this.lineVerifyUrl = response.line_verify_url;
				}
			}
		},
		async _sendMail(client) {
			let [managementMaster, managementMasterError] = await ManagementService.getAll();
			if (this.model.notifyMethod == 1) {
				let mailContents = {
					destinations: [client['email_address']],
					sender: managementMaster[0]['sender_email_address'],
					subject: '【CoMoDe】LINE通知の設定',
					template: 'createproject_line',
					template_params: {
						lineVerifyUrl: this.lineVerifyUrl,
					},
				};
				Mail.sendMail(mailContents);
				let creatorMailContent = structuredClone(mailContents);
				creatorMailContent.destinations = [this.userInfo['email_address']];
				creatorMailContent.template_params.lineVerifyUrl =
					await this.createLineVerifyUrl();
				Mail.sendMail(creatorMailContent);
			} else {
				let mailContents = {
					destinations: [this.userInfo['email_address']],
					sender: managementMaster[0]['sender_email_address'],
					subject: `【CoMoDe】［${this.model.projectName}］作成しました`,
					template: 'createproject_mail_creator',
					template_params: {},
				};
				await Mail.sendMail(mailContents);

				let clientMailContent = structuredClone(mailContents);
				clientMailContent.subject = `【CoMoDe】［${this.model.projectName}］のURLが届きました`;
				clientMailContent.destinations = [client['email_address']];
				clientMailContent.template = 'createproject_mail_client';
				clientMailContent.template_params = {
					creatorName: this.userInfo['register_name'],
					processingScreenUrl: this.processClientUrl,
				};
				await Mail.sendMail(clientMailContent);
			}
		},
	},
};
</script>
<style scoped>
.register_option:after {
	content: '';
	position: absolute;
	left: 2%;
	bottom: 0;
	height: 1px;
	width: 96%;
	border-bottom: 1px solid #606266;
}
</style>
